import 'core-js/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import { AppContainer } from 'react-hot-loader';
import '@audacious/components/lib/styles/index.css';
import '@audacious/fluxible-notifications/lib/notifications.css';
import tenantModule from '@audacious/tenant-admin-module';
import reportModule from '@audacious/ainq-admin-report-module';
import pulseModule from '@audacious/pulse-admin-module';
import promptModule from '@audacious/prompt-admin-module';
// eslint-disable-next-line
import AdminPortal from '../dist';
import configurations from './configurations';

const modules = [
    tenantModule(configurations.tenant),
    reportModule(configurations.report),
    pulseModule(configurations.pulse),
    promptModule(configurations.prompt),
];

const adminPortal = new AdminPortal(modules);

const context = adminPortal.createContext(configurations.application);

const Component = adminPortal.getComponent();

ReactDOM.render(
    <AppContainer>
        <Component context={context.getComponentContext()} />
    </AppContainer>,
    document.getElementById('application'),
);
