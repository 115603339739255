import Loki from 'lokijs';
import forEach from 'lodash/forEach';
import defaultTo from 'lodash/defaultTo';
import isNil from 'lodash/isNil';
import Symbol from 'es6-symbol';

const FIELDS = {
    DB: Symbol('db'),
};

function getCollection(db, collectionId) {
    let collection = db.getCollection(collectionId);

    if (isNil(collection)) {
        collection = db.addCollection(collectionId);
    }

    return collection;
}

class DatabaseMock {
    constructor(initialCollections) {
        const db = new Loki('axios.mock.db');

        this[FIELDS.DB] = db;

        forEach(initialCollections, (collectionData, collectionId) => {
            const collection = db.addCollection(collectionId);

            collection.insert(collectionData);
        });
    }

    getCollectionData(collectionId) {
        const db = this[FIELDS.DB];

        const collection = getCollection(db, collectionId);

        return defaultTo(collection.data, []);
    }

    insert(collectionId, value) {
        const db = this[FIELDS.DB];

        const collection = getCollection(db, collectionId);

        collection.insert(value);
    }

    find(collectionId, target) {
        const db = this[FIELDS.DB];

        const collection = getCollection(db, collectionId);

        return collection.findOne(target);
    }

    update(collectionId, item) {
        const db = this[FIELDS.DB];

        const collection = getCollection(db, collectionId);

        collection.update(item);
    }
}

export default DatabaseMock;
