"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.createServiceConfiguration = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var _merge = _interopRequireDefault(require("lodash/merge"));
var _get = _interopRequireDefault(require("lodash/get"));
(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};
function processMockConfigurations() {
  var mockConfig = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var serviceMocks = arguments.length > 1 ? arguments[1] : undefined;
  var _mockConfig$services = mockConfig.services,
    services = _mockConfig$services === void 0 ? {} : _mockConfig$services,
    rest = (0, _objectWithoutProperties2.default)(mockConfig, ["services"]);
  return _objectSpread({
    services: (0, _merge.default)({}, serviceMocks, services)
  }, rest);
}
var _default = function _default() {
  var servicesConfig = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var serviceMocks = arguments.length > 1 ? arguments[1] : undefined;
  return {
    mock: processMockConfigurations(servicesConfig.mock, serviceMocks)
  };
};
var _default2 = exports.default = _default;
var createServiceConfiguration = exports.createServiceConfiguration = function createServiceConfiguration(config) {
  return {
    global: (0, _get.default)(config, 'global', {})
  };
};
;
(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;
  if (!reactHotLoader) {
    return;
  }
  reactHotLoader.register(processMockConfigurations, "processMockConfigurations", "/builds/audacious-inquiry/portfolio/web-platform/admin-portal-application/clone/src/services/index.js");
  reactHotLoader.register(createServiceConfiguration, "createServiceConfiguration", "/builds/audacious-inquiry/portfolio/web-platform/admin-portal-application/clone/src/services/index.js");
  reactHotLoader.register(_default, "default", "/builds/audacious-inquiry/portfolio/web-platform/admin-portal-application/clone/src/services/index.js");
})();
;
(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();