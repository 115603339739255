import initialDbData from './initial-db-data';
import DatabaseMock from './database-mock';

const configurations = {
    platform: {
        endpoints: {
            tenants: {
                retrieve: '',
                create: '',
                update: '',
                delete: '',
            },
        },
    },
    tenant: {},
    organization: {},
    report: {},
    pulse: {},
    application: {
        services: {
            mock: {
                include: [
                    // 'loadApplicationConfiguration',
                    // 'loadApplicationLocalization',
                    // 'authorize',
                    // 'resume',
                    // 'logout',
                ],
                enabled: false,
                db: new DatabaseMock(initialDbData),
            },
        },
    },
};

export default configurations;
